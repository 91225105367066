"use client";

import { GenericErrorMessage } from "@/components/GenericErrorMessage/GenericErrorMessage.component";
import { useCaptureException } from "@/hooks/use-capture-exception.hook";
export default function GlobalError({
  error,
  reset
}: {
  readonly error: Error & {
    readonly digest?: string;
  };
  readonly reset: () => void;
}) {
  useCaptureException(error);
  return <html lang="en" suppressHydrationWarning data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body suppressHydrationWarning>
        <div className="flex h-screen items-center justify-center text-black" style={{
        fontFamily: "arial, sans-serif"
      }}>
          <GenericErrorMessage reset={reset} data-sentry-element="GenericErrorMessage" data-sentry-source-file="global-error.tsx" />
        </div>
      </body>
    </html>;
}